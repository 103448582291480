.vp-form-field {
    position: relative;
    margin-bottom: 24px;
}

.vp-form-field input {
    border: 1px solid #e0e0e0;
    font-size: 1rem;
    line-height: 24px;
}

.date-field-date:hover {
    background-color: aqua;
}
.kw-datepicker button {
    border: none;
    border-radius: 25px;
    padding: 8px 16px;
    cursor: pointer;
}
.kw-action-button {
    border-radius: 25px;
    padding:  0;
    height: 40px;
    width: 40px;
    border: none;
    padding: 8px !important;
    box-sizing: border-box;
}
.kw-action-button:last-child {
    margin-left: 8px;
}



.kw-action-button:first-child {margin-left: none;}
