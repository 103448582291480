.ride-list {
    width: 480px;
    position: absolute;
   
}
.user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 16px 16px 0 16px;
}

@media screen and (min-width: 1200px) {
    .ride-list {
     left: 16px;
     bottom: 0;
     max-height: calc(100% - 32px);
     overflow: auto;
    width: 480px;
    }

    .ride-list ul {
        width: 100%;
        padding: 0;
        margin:0;
        box-sizing: border-box;
       
    }
    .ride-list ul li {
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: left;
        box-sizing: border-box;
        padding: 16px;
        cursor: pointer;
        border-bottom: 1px solid #e0e0e0;
       
    }

    .ride-list ul li:last-child {

        border-bottom: none;
    }

    .ride-list ul li:hover {
        background-color:  #01c38f27;

    }

    .ride-mets {
        display: flex;
    }
    .ride-mets span {
        margin-right: 16px;
    }

    .ride-info-driver {
        width: 480px;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 16px;
        bottom: 0px;
    }

    .driver-ride-list {
        padding: 16px;
        width: 100%;
        box-sizing: border-box;


    }

    .kwtable {
        border: 1px solid transparent;
        border-radius: 16;
        overflow: hidden;
    }

    .driver-ride-table {
        margin: auto;
        width: 100%;
        border: 2px solid transparent;
        text-align: left;
        border-collapse: collapse;
      
        
    }
   
    .driver-ride-table th {
        padding: 16px 16px;
        height: 60px;
        box-sizing: border-box;
      
        border-right: 1px solid #e0e0e0;
    
    }
    .driver-ride-table th:last-child {
        border-right: none;
    }
    .driver-ride-table td {
        padding:  16px 16px;
        border: none;
        border-right: 1px solid #e0e0e0;
    }
    .driver-ride-table td:last-child {
        border-right: none;
    }
    
    @media screen and (min-width: 1200px) {

        .driver-ride-list {
            width: 1180px;
            margin: auto;
        }
        
    }


    
}