
:root {
  --accent: #01c38e;
    --on-primary: #002d6e;
    --primary: #b0c6ff;
    --primary-container: #00429a;
    --secondary: #bfc6dc;
    --on-secondary: #293042;
    --secondary-container: #404659;
    --neutral: #404659;
    --surface1: #e1e2ec66;
    --outline: #757780;
    --background: #191c1a;
    --surface10: #141316;

}
body {
  margin: 0;
  font-family: /*-apple-system, BlinkMacSystemFont, 'Segoe UI',*/ 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  background: rgb(39,39,39);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
