/*mobile styles */
.register-rider {
   
   /* background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);*/
    padding: 32px 16px;
    box-sizing: border-box;
   margin-top: 64px;
    width: 100%;
    
}

.rider-reg-page {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    height: calc(100vh - 72px);
    align-items: flex-start;
}


.rider-reg-page::before {
   /* background-color: #212121;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0,0,0,0.25));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;*/
    width: 100%;
    content: '';
    z-index: -1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.register-rider form {
    text-align: left;
    
}

.resend-code {
    text-align: left;
    text-decoration: none;
}
.resend-code button {
    background: none;
    border: none;
    color: blue;
    padding: 12px 0;
}

@media (min-width: 1200px) {
    .register-rider {
        width: 550px;
        margin: 72px auto;
       
      
        border-radius: 24px;
        
    }
    .rider-reg-page {
        display: grid;
        grid-template-columns: 6fr 1fr ;
    }

    .rider-reg-page .side-show {
       
       
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: start;
       
        text-align: left;
    }

    .rider-reg-page .side-show img {
        height: 480px;
    }
    

  
}

@media (min-width: 1400px) {
    .rider-reg-page {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }

}