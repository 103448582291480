.App {
  text-align: center;
}
.form-field {
  margin-bottom: 16px;
}


h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 66px;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
}

.mobile-toolbar{
  position: fixed;
  right: 8px;
  display: flex;
  top: 8px;
  z-index: 999;
  width: 52px;
  height: 52px;
}

.full-width {
  display: block;
  width: 100%;
}
a { color: #23906d}

.app-buttons {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  box-sizing: border-box;

  
}

.app-buttons button:hover {
  border: 2px solid #2596be;
  background: #23906d;

  outline: none;
}
.app-buttons button:active {
  box-shadow: 0 0 0 3px #2595bea8;
}
.selected {
  box-shadow: 0 0 0 2px #2595bea8;
}



.login-type-buttons button {
  border-radius: 5px;
  border: 2px solid #37C597;
  padding: 12px 24px;
  height: 58px;
  font-size: 1.3rem;
  box-sizing: border-box;
}

.avatar {
  border-radius: 50%;
  border: 3px solid #e0e0e0;
  position: relative;
  margin: auto;
}

.avatar img {
  border-radius: 50%;
}

label {
  font-size: .75rem;
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
}

.full-width input {
  width: 100%;
}

input {
  border: 1px solid #e0e0e0;
  padding: 12px 16px;
  box-sizing: border-box;
}

button {
  background: #202020;
  color: #fefefe;
  border: 1px solid #e0e0e0;
  padding: 12px 16px;
  font-weight: 600;
  cursor: pointer;
}
.landing-bg {
  width: 100%;
}

.landing-bg img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
 
  
}

.icon-button {
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  padding: 12px;
}

.large-title {
  font-size: 4rem;
  margin: 0;
}

button:disabled {
  background-color: #e0e0e0;
}

.primary {
  background: #212121;
  color: #fefefe;
}
.accent {
  background-color: #01c38e;
 /* background: #37C597;*/
  color: #eeeded;
  
}


.app-buttons button:hover {
  border: 2px solid #37C597;
  background: #23906d;

  outline: none;
}
.app-buttons button:active {
  box-shadow: 0 0 0 3px #37c59850;
}
.selected {
  box-shadow: 0 0 0 2px #2595bea8;
}

.profile-section {
  padding: 16px;
  margin-top: 16px;
  border-radius: 16px;
}


.app-buttons button {
  border-radius: 5px;
  border: 2px solid #37C597;
  padding: 12px 24px;
  height: 58px;
  font-size: 1.3rem;
  box-sizing: border-box;
}

.socandary {
  background-color: #f0f0f0;
}

.space {
  flex: 1 1 auto;
}
.drop-down {
  position: relative
   
}
.dd-list {
  position: absolute;
  left: 0;
  background: #fefefe;
  margin: 0;
  padding: 0;
  padding-top: 8px;
  list-style: none;
  max-height: 400px;
  border: 1px solid #e0e0e0;
  overflow: auto;
  width: calc(100% - 2px);
  border-top: none;
  z-index: 99;
}
.dd-list li {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 36px;
}
.title-medium {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 42px;
}


.app-bar{
  width: 100%;
  height: 64px;


  display:  none;
  justify-content: center;
  
}

.static {
  position: fixed;
  top: 0;
}

.app-bar-body, .app-bar-body-wide  {
  padding: 12px 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
}



.bar-left {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.App-link {
  color: #61dafb;
}

.app-bar a {
  text-decoration: none;
  color: #fefefe;
}

.links span {
  cursor: pointer;
}

.links span:hover {
  color: #03dac5;
}

.alert {
  padding: 16px;
  border-radius: 4px;
  
  text-align: center;
  animation: fade-in .5s;
}

.error {
  background: #B00020;
  border: 1px solid #B00020;
  color: #fefefe;
}

.success {
    background-color: #198754;
    border: 1px solid #198754;
    color: #fefefe;
}

.info {
  background-color: #475F57;
  color: #fefefe;
  border: 1px solid #475F57;
}


.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

.white-contrast {
  background-color: #ECEFF1;
}

.fullscreen-map {
  width: 100%;
  height: 100vh;
  position: relative;
}

.app-map {height: calc(100% - 0px); width: 100%; width:100vw; position: relative;}
.app-map canvas {width: 100%;}



.fullscreen-map-with-toolbar {
  width: 100%;
  height: calc(100vh - 72px);
  padding-top: 72px;
  display: flex;
  align-items: stretch;
  position: relative
}



.card {
  box-shadow: 0px 0.125rem 0.25rem rgb(0 0 0 / 8%), 0px 0.25rem 1.5rem rgb(0 0 0 / 8%);

}
.h3-col {
  display: flex;
  justify-content: space-evenly;
}

.breathe {
  animation: pulsate 1.5s both infinite;
}

.glass {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.success-screen {
  padding: 16px;
}

.forget-password {
  display: flex;
  justify-content: stretch;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 64px;
}
.forget-password form {
  width: 100%;
  box-sizing: border-box;
}




.kw-list {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.kw-list li {
  display:  flex;
  flex-direction: column;
  padding: 12px 16px;
  text-align: left;
}
.kw2-row {
  display: flex;
  flex-direction: column;
  padding: 8px 0;

}

.kw-2col {
  display: flex;
  align-items: center;
  flex-direction: row !important;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.95);
}


.modal-positional {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.95);
  animation: slideup .4s;

}

.kw-field {
  margin-bottom: 16px;
  box-sizing: border-box;
}

.kw-button {
  border: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: center;
  border-radius: 8px;
  min-height: 48px;
}
.kw-button button {
  padding: 12px 16px;
  min-height: 46px;
  cursor: pointer;
  border-radius: 8px;
  
}

.kw-button button:focus {
  outline: none;

}
.kw-app-toolbar {
  display: flex;
  height: 72px;
  align-items: center;
  padding: 12px 16px;
  box-sizing: border-box;
}
.kw-toolbar-title {
  font-size: 1.15rem;
  font-weight: bold;
}

.kw-toobar-actions {

}
.kw-toolbar-space {
  padding: 0 16px;
  flex: 1 1 auto;
}




@keyframes pulsate {
  0% {
    color: red;
    transform: scale(.5);}
  50% {
    color: orange;
    
  }
  100% {
    color: green;
    transform: scale(1);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes  fade-in{
  from {opacity: 0; transform: scaleY(0);}
  to {opacity: 1; transform: scaleY(1);}
}

@keyframes swiftin {
  0% {
    opacity: 0;
    transform: translateY(48px);
    transform: scaleY(.5);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transform: scaleY(1);
  }
}

@keyframes heightin {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideup-mobile {
  from {
    
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
   
    transform: translateY(1);
    opacity: 1;
  }
}

@keyframes slidedown-placessearch {
  from {
    
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
   
    transform: translateY(1);
    opacity: 1;
  }
}


@keyframes slideup {
  from {
    
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
   
    transform: translateY(1);
    opacity: 1;
  }
}

@keyframes close-frame-placesearch {
  from {
    opacity: 100%;
    transform: translateY(1);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.app-bar-body {
  max-width: 1320px;
 
}

.content-body {
  width:  100%;;
}

@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {
  .App {
    
  }
  .app-bar {
    display: flex;
  }
  .mobile-toolbar {
    display: none;
  }
  .intro {
    text-align: left;
  }
  
  .app-bar-body-wide {
    width: 100%;
  }
  .bar-left {
    flex: 1 1 auto;
  }
  .bar-right {
    justify-self: right;
    
  }
  .app-bar-body-wide .brand {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .bar-right span {
    margin: 0 8px;
  }

  .bar-left .links {
    display:  flex;
    align-items: center;
    margin-left: 32px;
    
  }
  .links span {
    margin: 0 8px;
  }
  .app-body {
    width: 1180px;
    margin: auto;
    padding-top: 48px;
  }

  .success-screen {
    
    margin: auto;
  }
  .forget-password form{
    width:  480px;

  }

  ::-webkit-scrollbar {
    width: 10px;
    border-radius: 25px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .multi-input {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    column-gap: 16px;
  }
  .profile-section-fields {
    width: 480px;
  }
  .mobile-menu {
    display: none;
  }

  .forget-password {
    margin-top: 0;
  }

  .trip-summary {
    width: 600px;
  }

}

@media(min-width:1400px) {
  .content-body {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
  .app-bar-body {
    width: 100%;
    padding-left: .75rem;
    padding-right: .75rem;
    margin-right: auto;
    margin-left: auto;
    
  }
  .landing-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 6fr; 

  }

  .landing-bg {
    position: absolute;
    top: 0;
    z-index: 1;
    text-align: left;
    height: calc(100vh - 100px);

    overflow: hidden;
  }
 

  
}

