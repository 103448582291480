.rider-login {
    position: relative;
    height: calc(100vh - 72px);
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    
}
.side-show {
    display: none;
}

.rider-login::before {
  /*  background-color: #212121;
  
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    width: 100%;
    content: '';
    z-index: -1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;*/
}
.login-form {
    width: 100%;
    margin-top: 64px;
}

.login-form form {
   /* background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); */
   
    padding: 40px 16px;
    width: 100%;
    box-sizing: border-box;
}

.payment-selector li{

  padding: 16px 0;
}

@media only screen and (min-width:1200px)  {
    .rider-login::before {
        background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0,0,0,0.25)), url("/public/images/bg.png");
        background-position: center top;
    }

    .side-show {
        overflow: hidden;
        display: block;
    }

    .side-show img {
        height: 100%;
        max-width: 100%;
        object-fit: fill;
    }

    .login-form {
        margin-top: 0;
        padding-top: 64px;
    }
    
    .login-form form {
      
        width: 480px;
       /* border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.025);*/
        border-radius: 16px;
        padding: 24px;
        margin: 48px auto;
        
    }
    .rider-login {
        grid-template-columns: 6fr 4fr;
        margin-left: auto;
        margin-right: auto;
    }

}

@media (min-width: 1400px) {
    .rider-login {
        max-width: 1320px;
    }
}