.rider-profile {
    padding: 16px;
}

.rider-form form {
    margin-top: 16px;
}

.rider-image {
    width: 192px;
    height: 192px;
    border-radius: 50%;
    border: 3px solid #e0e0e0;
    margin: auto;
    text-align: center;
    
    position: relative;
}

.rider-spinner {
    padding: 16px;
    display: flex;
}


@media(min-width:1200px) {
    .rider-profile {
        width: 400px;
        margin: auto;
    }
}