.edit-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.source-select {
    background: #efefef;
    height: auto;
    padding: 16px 0;
    border: 2px solid #e0e0e0;
    border-top: none;
}

@keyframes slide-down{
    from {opacity: 0; transform: translateY(-5px);}
    to {opacity: 1; transform: translateY(72px);}
}

.source-select-container {
    animation: slide-down .4s forwards;
    position: fixed;
    width: 100%;
    height: 100vh;
    left:0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.4);
}

@media(min-width: 1200px) {
    .source-select {
       width: 400px;
    }
    .vid-snap {
        width: 400px;
        height: 400px;
    }
}