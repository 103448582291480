.intro {
    text-align: center;
    padding-top: 72px;
    color: #fefefe;
}

.login-type-buttons {
    display: flex;
    
    flex-direction: column;
    justify-content: stretch;
    padding: 16px;

}
.login-type-buttons button:first-child {
    margin-bottom: 24px;
}
.login-type-buttons button:hover {
    border: 2px solid #2596be;
    background: #23906d;
  
    outline: none;
}
.login-type-buttons button:active {
    box-shadow: 0 0 0 3px #2595bea8;
}
.selected {
    box-shadow: 0 0 0 2px #2595bea8;
}

.login-type-buttons a {
    text-decoration: none;
    color: #efefef;
}



.login-type-buttons button {
    border-radius: 5px;
    border: 2px solid #37C597;
    padding: 12px 24px;
    height: 58px;
    font-size: 1.3rem;
    box-sizing: border-box;
    width: 100%;
}
.login-page {
    position: relative;
    padding-top: 64px;
    box-sizing: border-box;

    height: calc(100vh - 64px);
}
.login-page::before {
    background-color: #212121;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0,0,0,0.25)), url("/public/images/bg-420.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    width: 100%;
    content: '';
    z-index: -1;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
}

@media only screen and  (min-width :1200px) {
    .login-page {
        padding-top: 0;
    }
    .intro {
        text-align: left;
        width: 576px;
        margin: auto;
       
        height: 370px;
    }

    .login-type-buttons {
        flex-direction: row;
        padding: 0;
        align-items: center;
    }
    .login-type-buttons button {
        width: auto;
    }

    .login-type-buttons button:first-child {
        margin-right: 24px;
       
    }
    .login-page::before{
        background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0,0,0,0.25)), url("/public/images/bg.png");
        background-position: center center;
    }
   
    
}

@media(min-width: 1400px) {
    .login-page {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}