.card-payment label {
    margin-top: 16px;
   
}
.card-payment {
    background-color: #efefef;
    padding: 16px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
}


.card {
    
}

.paypal-powered-bu {
    display: none;
}

@media screen and (min-width: 1200px) {
    .card-payment {
        width: 600px;
        border-radius: 16px;
        overflow: hidden;
    }
}

