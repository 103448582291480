.register-driver {
   overflow: auto;
   width: 100%;
   text-align: left;
   margin-top: 64px;
 
}
.register-driver-left {
    display: none;
}
.register-container {
    height: calc(100vh - 80px);
    overflow: auto;
    width: 100%;
}
.tos {
    font-size: .8rem;
    text-align: justify;
}

.password {
    position: relative;
}

.suffix-icon {
    position: absolute;
    right: 16px;
    top: 10px;
}
.driver-registration-form {
    border-radius: 0;
}

@media (min-width: 1200px) {
    .register-driver-left {
        display: block;
    }
    .register-driver-left img {
        width: 480px;
        margin-top: 64px;
        
        
    }
    .register-driver {
        max-width: 1100px;
        display: flex;
        text-align: left;
        margin: 72px auto;
    }

    .driver-registration-form {
        width: 500px;
        border-radius: 16px;
        margin-top: 32px;
        

    }
}

@media (min-width: 1400px) {
    .register-driver {
        max-width: 1320px;
        margin-left: auto;
        margin-right: auto;
    }
}