.ride-page {
    text-align: left;
}
.selection-form {
   width: 100%;
 
   background-color: var(--surface10);
   position: fixed;
   z-index: 999;
   pointer-events: none;
   bottom: 0;
   left: 0;
   right: 0;
   border: 1px solid var(--surface10);
   padding: 0;
   box-sizing: border-box;
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
   
}
.expand-form {
    display: block;
}
 
.place-search {
   
}

.selection-form form button {
    width: 100%;
}
.selection-form  form {
    display: flex;
    padding: 16px;
   
    
}

.mobile-slide {
    animation: slidedown-placessearch.4s;
}

.mobile-slide-up {
    animation:  close-frame-placesearch .3s;
}
.selection-form form input {

}
.ride-metrics {
    font-size: small;
}

.fare-heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    padding-top: 0;
   
}
.ride-cost {
    display: flex;
    align-items: flex-start;
    padding: 8px 0 24px 0;
    text-align: left;
    justify-content: space-between;
}

.ride-metrics:last-child {
   
}

.map {
    position: relative;
    height: 100vh;
    width: 100%;
    width: 100vw;
    overflow: hidden;
   

}
.map canvas {
    left: 0;
    right: 0;
    
   
}
.the-map {
    width: 100%;
   
    
    position: relative
}

.map-menu {
    position: absolute;
    z-index: 999;
    background: #fefefe;
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 16px;
    animation: fade-in .4s;
    min-width: 200px;
    overflow: hidden;
}
.map-menu li {
    padding: 16px 16px 16px 16px;
    cursor: pointer;
    border-bottom: 1px solid #01c38e;
}
.map-menu li:first-child {
    padding-top: 16px;

}
.map-menu li:last-child {
    border-bottom: none;
}
.payment-selector {
    list-style: none;
    width: 100%;
}
.payment-selector li {
    
    cursor: pointer;
    color: #efefef;
}
.payment-selector :hover {

}
.vepp-payments {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
   padding: 16px;
   overflow-y: auto;
   overflow-x: hidden;
   
}

.ride-stops li {
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    text-align: left;
    height: 64px;
    display: flex;
    align-items: center;
}

.ride-stops li::before {
    background-color: #01c38e;
    height: 100%;
    position: absolute;
    left: -16px;
    top:50%;
    display: flex;
    align-items: center;
    color: #01c38e;
    flex-direction: column;
    line-height: 0;
    box-sizing: border-box;

    width: 2px;
    content: '\25C9';
}
.ride-stops li:first-child::before {
    content: '\25A3';

}
.u-dd{
    position: absolute;
    right: 0px;
    list-style: none;
    padding: 0;
    background-color: #efefef;
    min-width: 120px;
    padding: 0;
    margin: 0;
    border: 1px solid #e0e0e0;
    color: #444;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    animation: fade-in .4s;
    z-index: 9999;
   
}

.u-dd li {
    padding: 12px;
   
}

.ride-stops li:last-child::before {
    height: 0;
    content: '\25A3';
}

.places-form {
    display: flex;
    flex-direction: column  ;
    justify-content: center;
    justify-content: center;
}

.no-map {
    display:  flex;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%; 
    flex-direction: column;
}
.left-content {
    display:  none;
    width: 0;
}
.right-content {
    width: 100%;
}
.summary-section {
    background-color:  rgba(39,39,39, .8);
    border-radius: 16px;
}
.rounded-card {
    border-radius: 16px;
    background-color:  rgba(39,39,39, .7);
}

.ride-type {
    border-radius: 0;
}



@media(min-width: 1200px) {
    .ride-type {
        border-radius: 0;
    }

    .map-bar {
        border-radius: 0;
      
    }

    .place-search {
        padding: 0;
    }
    
    .selection-form {
        border-radius: 0;
        width: 400px;
        height: calc(100vh - 64px);
        box-sizing: border-box;
        position: absolute;
        top: 65px;
        z-index: 99;
        background-color: var(--background);
        left: 0px;
        pointer-events: all;
        border-radius: 0;
        padding: 16px;
       /* background-color: rgb(39, 39, 39);*/
    }
    .selection-form form {
        display: flex;
         flex-direction: column;
         justify-content: flex-start;
        
    }
    .vepp-payments {
        margin-top: 56px;
       width: 580px;
       margin: auto;
       
    }
    .payment-selector {
        width: 600px;

    }

    .selection-form form button {
        height: 54px;
    }
  
   
    .map canvas {
      /*  width: 100%;*/    
    }
    .no-map {
       height:  calc(100vh - 20px); 
       flex-direction: row;
    }
    .right-content {
        width: auto;
    }

    .left-content {
        display: block;
        width: 100%;
    }

    .mobile-slide {
        animation: none;
    }

  

    .rounded-card {
        border-radius: 16px;
        background-color:  rgba(39,39,39, 1);
    }

    .summary-section {
        margin-top: 0
        
    }

    .cash-pay {
        margin-top: 28px;
        box-sizing: border-box;
    }

    .expand-form {
        display: none;
    }
    .vepp-payments {width: 600px; padding-left: 0; padding-right: 0;}
    
    
}